.metamask-modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  animation: fadeIn 0.3s;
}

.metamask-modal__content {
  background-color: #181c1f;
  padding: 20px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 359px;
  height: 146px;
}

.metamask-modal__title {
  color: white;
  font-size: 22px;
  font-weight: 500;
  font-family: "DM Sans", sans-serif;
}

.metamask-modal__button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #2f3235;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  padding: 10px;
  border-radius: 7px;
  width: 95%;
  cursor: pointer;
  border: 1px solid #ffffff1a;
}

.metamask-modal__icon {
  width: 24px;
  height: 24px;
}

.metamask-modal__text {
  color: white;
  font-size: 16px;
  flex-grow: 1;
  text-align: center;
  margin-right: 1rem;
  margin-top: 4px;
}
